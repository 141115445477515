import { PermissionGuard } from "@/services/user.service";
import type { Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";

export const routes: Routes = [
	{
		path: "",
		loadComponent: () => {
			return import("@/pages/homepage/homepage.component").then((m) => m.HomepageComponent);
		},
	},
	{
		path: "",
		canActivate: [MsalGuard],
		canActivateChild: [PermissionGuard],
		loadComponent: () => {
			return import("@/components/app-skeleton/app-skeleton.component").then((m) => m.AppSkeletonComponent);
		},
		children: [
			{
				path: "dashboard",
				loadComponent: () => {
					return import("@/pages/dashboard/dashboard.component").then((m) => m.DashboardComponent);
				},
			},
			{
				path: "risk-element",
				pathMatch: "full",
				loadComponent: () =>
					import("@/pages/risk-element/risk-element.component").then((m) => m.RiskElementComponent),
			},
			{
				path: "risk-element/:id",
				pathMatch: "full",
				loadComponent: () =>
					import("@/pages/risk-element-detail/risk-element-detail.component").then(
						(m) => m.RiskElementDetailComponent,
					),
			},
			{
				path: "element-details/:assetId/:elementId",
				pathMatch: "full",
				loadComponent: () =>
					import("@/pages/risk-element-detail/elementdetails-details/elementdetails-details.component").then(
						(m) => m.ElementdetailsDetailsComponent,
					),
			},
			{
				path: "review-plan/:id",
				pathMatch: "full",
				loadComponent: () =>
					import("@/pages/risk-element-detail/review-plan/review-plan.component").then(
						(m) => m.ReviewPlanComponent,
					),
			},
			{
				path: "owner",
				pathMatch: "full",
				loadComponent: () =>
					import("@/pages/owner-details/owner-details.component").then((m) => m.OwnerDetailsComponent),
			},
			{
				path: "risk-import",
				loadComponent: () =>
					import("@/pages/risk-import/risk-import.component").then((m) => m.RiskImportComponent),
			},
			{
				path: "risk-coefficient",
				loadComponent: () =>
					import("@/pages/risk-coefficient/risk-coefficient.component").then(
						(m) => m.RiskCoefficientComponent,
					),
			},
			{
				path: "risk-table",
				loadComponent: () =>
					import("@/pages/risk-tables/risk-tables.component").then((m) => m.RiskTablesComponent),
			},
			{
				path: "entitlement",
				pathMatch: "full",
				loadComponent: () =>
					import("@/pages/entitlement/entitlement.component").then((m) => m.EntitlementComponent),
			},
			{
				path: "entitlement/role/:id",
				pathMatch: "full",
				loadComponent: () =>
					import("@/pages/roles-details/roles-details.component").then((m) => m.RolesDetailsComponent),
			},
			{
				path: "entitlement/user/:id",
				pathMatch: "full",
				loadComponent: () =>
					import("@/pages/user-details-page/user-details-page.component").then(
						(m) => m.UserDetailsPageComponent,
					),
			},
			{
				path: "logs/:logName",
				pathMatch: "full",
				loadComponent: () => import("@/pages/logs/logs.component").then((m) => m.LogsComponent),
			},
		],
	},
	{
		path: "access-denied",
		loadComponent: () =>
			import("@/pages/access-denied/access-denied.component").then((m) => m.AccessDeniedComponent),
	},
	{
		path: "**",
		loadComponent: () => import("@/pages/not-found/not-found.component").then((m) => m.NotFoundComponent),
	},
];
